/**
 * @flow
 */

import cloneDeep from "lodash/cloneDeep";
import shuffle from "lodash/shuffle";

type ziuqthingSingleAnswer = {|
    entry: string,
    correct?: boolean,
|};
type ziuqthingQuestionContainer = {|
    question: string,
    code: string,
    img?: string,
    answers: Array<ziuqthingSingleAnswer>,
|};

type listenerCallback = (ziuqthingHandler) => void;

type ziuqthingMode = "ONCE_A_DAY" | "UNTIL_CORRECT";

class ziuqthingHandler {
    mode: ziuqthingMode = "UNTIL_CORRECT";
    points: number = 0;
    questionList: Array<ziuqthingQuestionContainer> = [
        {
            question:
                "Ute står ein jævel og frys, men ved kva temperatur frys nitrogen?",
            img: "ovn.jpg",
            code: "ON",
            answers: [
                {
                    entry: "-209 °C",
                    correct: true,
                },
                {
                    entry: "-309 °C",
                },
                {
                    entry: "-109 °C",
                },
                {
                    entry: "-9 °C",
                },
                {
                    entry: "-409 °C",
                },
            ],
        },
        {
            question:
                "Ingen av oss vart einige om korleis namnet til denne hunden skrives. Korleis meinte Eskild det skrives?",
            img: "barthes.jpg",
            code: "DG",
            answers: [
                {
                    entry: "Barthes",
                    correct: true,
                },
                {
                    entry: "Barthez",
                },
                {
                    entry: "Bartes",
                },
                {
                    entry: "Bartez",
                },
            ],
        },
        {
            question:
                "Denne Linux distribusjonen var Eskild med på å lage i ein periode. Kva var namnet til den?",
            img: "mdv.png",
            code: "MV",
            answers: [
                {
                    entry: "Mandriva",
                    correct: true,
                },
                {
                    entry: "Connectiva",
                },
                {
                    entry: "Ubuntu",
                },
                {
                    entry: "Red Hat",
                },
                {
                    entry: "Debian",
                },
            ],
        },
        {
            question: "Kva er rett?",
            code: "ST",
            answers: [
                {
                    entry: "Star Trek",
                    correct: true,
                },
                {
                    entry: "Star Wars",
                },
            ],
        },
        {
            question: "Kva heiter denne TV serien?",
            code: "TV",
            img: "lietome.jpg",
            answers: [
                {
                    entry: "Lie to me",
                    correct: true,
                },
                {
                    entry: "Lies",
                },
                {
                    entry: "Liar liar",
                },
            ],
        },
        {
            question: "Kva heiter dette dyret frå Final Fantasy?",
            code: "FFXIV",
            img: "chocobo.jpg",
            answers: [
                {
                    entry: "Chocobo",
                    correct: true,
                },
                {
                    entry: "Kylling",
                },
                {
                    entry: "Fat cat",
                },
                {
                    entry: "Moogle",
                },
            ],
        },
        {
            question: "Kven må passe seg når du er ute og køyrer?",
            code: "BL",
            answers: [
                {
                    entry: "Ein viss fødselslege",
                },
                {
                    entry: "Covid-19-foreldre",
                },
                {
                    entry: "Begge to",
                    correct: true,
                },
            ],
        },
        {
            question: "Kva heiter katten vår?",
            code: "KT",
            answers: [
                {
                    entry: "Luclin",
                    correct: true,
                },
                {
                    entry: "Mortmort",
                },
                {
                    entry: "Luckylin",
                },
                {
                    entry: "Luckyduck",
                },
            ],
        },
        {
            question:
                "Springnotan er eit skummelt konsept. Kor var det me tok «springnotan» (sa eine me åt saman med)?",
            code: "SN",
            answers: [
                {
                    entry: "Møllen",
                    correct: true,
                },
                {
                    entry: "Taverna nadila",
                },
                {
                    entry: "Bistro italiano",
                },
            ],
        },
        {
            question: "Kor mykje får du i gåve frå Eskild og Lisbeth?",
            code: "GV",
            answers: [
                {
                    entry: "Får eit bilete og quiz",
                },
                {
                    entry: "2500 kr",
                    correct: true,
                },
                {
                    entry: "1000 kr",
                },
                {
                    entry: "1500 kr",
                },
                {
                    entry: "25 000 kr",
                },
            ],
        },
    ];
    _currentQuestions: Array<ziuqthingQuestionContainer>;
    _invalidList: Array<ziuqthingQuestion> = [];
    _listeners: Array<listenerCallback> = [];
    answerCode: string = "0";
    current: ziuqthingQuestion | null;
    currentID: number = 0;

    possiblePoints(): number {
        return this.questionList.length;
    }

    constructor() {
        this.reset();
    }

    next(): ziuqthingQuestion | null {
        this.currentID++;
        const entry = this._currentQuestions.shift();
        if (entry !== undefined) {
            this.current = new ziuqthingQuestion(this, entry);
        } else {
            this.current = null;
        }
        this.notifyListeners();
        return this.current;
    }

    isDone(): boolean {
        return this.current === null;
    }

    reset() {
        this._currentQuestions = cloneDeep(this.questionList);
        this.next();
    }

    registerCorrect(q: ziuqthingQuestion): number {
        this.answerCode += q.code;
        this.next();
        return this.points++;
    }

    registerIncorrect(question: ziuqthingQuestion): number {
        if (this.mode === "UNTIL_CORRECT") {
            this._currentQuestions.push(question.container);
        } else {
            this._invalidList.push(question);
        }
        this.next();
        return this.points;
    }

    listen(cb: listenerCallback) {
        this._listeners.push(cb);
    }

    notifyListeners() {
        for (const cb of this._listeners) {
            cb(this);
        }
    }
}

class ziuqthingQuestion {
    question: string;
    possibleAnswers: Array<ziuqthingSingleAnswer>;
    handler: ziuqthingHandler;
    code: string;
    container: ziuqthingQuestionContainer;
    img: ?string;

    constructor(
        handler: ziuqthingHandler,
        question: ziuqthingQuestionContainer,
    ) {
        this.container = question;
        this.handler = handler;
        this.question = question.question;
        this.code = question.code;
        this.img = question.img;
        this.possibleAnswers = shuffle(question.answers);
    }

    answer(option: ziuqthingSingleAnswer): boolean {
        if (option.correct === true) {
            this.handler.registerCorrect(this);
            return true;
        } else {
            this.handler.registerIncorrect(this);
            return false;
        }
    }
}

export default ziuqthingHandler;
export { ziuqthingHandler, ziuqthingQuestion };

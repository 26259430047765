/**
 * @flow
 */

type onDoneCB = () => void;
class TBAudio {
    wrongAudio: Audio;
    done: Audio;
    loadedElements: number = 0;
    totalToLoad: number = 1;
    onDone: onDoneCB;
    loaded: boolean = false;
    loading: boolean = false;
    init(onDone: onDoneCB) {
        if (this.loading === true || this.loaded === true) {
            return;
        }
        this.loading = true;
        this.wrongAudio = new Audio("feil.mp3");
        this.onDone = onDone;
        this.wrongAudio.addEventListener(
            "canplaythrough",
            () => this._hasLoaded(),
            { once: true },
        );
    }
    playWrong(onDone: onDoneCB) {
        this.playElement(this.wrongAudio, onDone);
    }
    playElement(el: Audio, onDone: onDoneCB) {
        el.addEventListener(
            "ended",
            () => {
                onDone();
            },
            { once: true },
        );
        el.play();
    }
    _hasLoaded() {
        this.loadedElements++;
        if (this.loadedElements === this.totalToLoad) {
            this.loaded = true;
            this.onDone();
        }
    }
}
const handler: TBAudio = new TBAudio();
export default handler;

/*
 * @flow
 */

const WEEK_NO = 0;

function internationalDate(): string {
    const now = new Date();
    const year = now.getFullYear();
    const day = now.getDate();
    const month = now.getMonth() + 1;
    return (
        year +
        "-" +
        day.toString().padStart(2, "0") +
        "-" +
        month.toString().padStart(2, "0")
    );
}

export type ZiuqthingLS = {|
    score: number,
    possibleScore: number,
    submitted: boolean,
    answerCode: string,
    date: string,
    weekNo: number,
|};

function pushToLocalStorage(
    score: number,
    possibleScore: number,
    submitted: boolean,
    answerCode: string,
) {
    const object: ZiuqthingLS = {
        score,
        possibleScore,
        submitted,
        answerCode,
        date: internationalDate(),
        weekNo: WEEK_NO,
    };
    window.localStorage.setItem("ziuqthing", JSON.stringify(object));
}

function getFromLocalStorage(): ZiuqthingLS {
    const lsDefault = {
        score: 0,
        possibleScore: 0,
        submitted: false,
        answerCode: "0",
        date: "x",
        weekNo: WEEK_NO,
    };
    const lsItem = window.localStorage.getItem("ziuqthing");
    if (typeof lsItem === "string") {
        const parsed = JSON.parse(lsItem);
        if (
            typeof parsed.score === "number" &&
            typeof parsed.possibleScore === "number" &&
            typeof parsed.submitted === "boolean" &&
            typeof parsed.answerCode === "string" &&
            parsed.date === internationalDate() &&
            parsed.weekNo === WEEK_NO
        ) {
            return parsed;
        }
    }
    return lsDefault;
}

function clearLocalStorage() {
    window.localStorage.removeItem("ziuqthing");
}

export {
    internationalDate,
    pushToLocalStorage,
    getFromLocalStorage,
    clearLocalStorage,
};

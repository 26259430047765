/**
 * @flow
 */
// $FlowExpectedError[cannot-resolve-module]
import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import * as React from "react";
import {
    Card,
    CardTitle,
    CardBody,
    Col,
    Container,
    Row,
    ListGroup,
    ListGroupItem,
    Alert,
    Spinner,
} from "reactstrap";
import ziuqthingHandler, { ziuqthingQuestion } from "./ziuqthing";
import { clearLocalStorage, getFromLocalStorage } from "./utils";
import type { ZiuqthingLS } from "./utils";
import TBAudio from "./Audio.js";

type QuestionProps = {|
    question: ziuqthingQuestion | null,
|};
type QuestionState = {|
    wrongAnswer: boolean,
|};
class ScoreBoard extends React.Component<{|
    points: number,
    possiblePoints: number,
    answerCode: string,
    submitted?: boolean,
|}> {
    constructor() {
        super();
    }
    render() {
        return (
            <Container>
                <Row>
                    <Col>
                        <video
                            src={assetPreloader.videoData["MammaBursdag.mp4"]}
                            autoPlay
                            controls
                            className="img-fluid"
                        />
                    </Col>
                </Row>
            </Container>
        );
    }
}

class Question extends React.Component<QuestionProps, QuestionState> {
    state: QuestionState = {
        wrongAnswer: false,
    };
    render() {
        const { question } = this.props;
        let header: ?React.Node;
        if (question === null) {
            return null;
        }
        if (question.img !== undefined) {
            header = (
                <div key="header">
                    <img
                        src={question.img}
                        className="img-fluid mb-2 rounded"
                        alt=""
                    />
                </div>
            );
        }
        const options = [];
        const pushAnswer = (answer) => {
            if (answer.correct !== true) {
                if (document.body !== null) {
                    document.body.classList.add("bg-danger");
                }
                if (document.documentElement !== null) {
                    document.documentElement.classList.add("bg-danger");
                }
                this.setState({
                    wrongAnswer: true,
                });
                TBAudio.playWrong(() => {
                    question.answer(answer);
                    if (document.body !== null) {
                        document.body.classList.remove("bg-danger");
                    }
                    if (document.documentElement !== null) {
                        document.documentElement.classList.remove("bg-danger");
                    }
                });
            } else {
                question.answer(answer);
            }
        };
        if (this.state.wrongAnswer === true) {
            return (
                <Container>
                    <Row>
                        <Col>
                            <h1>FEIL!</h1>
                        </Col>
                    </Row>
                </Container>
            );
        }
        let id = 0;
        for (const possible of question.possibleAnswers) {
            id++;
            options.push(
                <ListGroupItem
                    key={"lgi" + id}
                    action
                    onClick={() => pushAnswer(possible)}
                >
                    {possible.entry}
                </ListGroupItem>,
            );
        }
        return (
            <Container>
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <CardTitle tag="h4">
                                    {header}
                                    {question.question}
                                </CardTitle>
                                <ListGroup>{options}</ListGroup>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }
}

class Ziuqthing extends React.Component<{||}> {
    handler: ziuqthingHandler;
    bootstrapped: ZiuqthingLS = {
        score: 0,
        possibleScore: 0,
        answerCode: "0",
        submitted: false,
        date: "x",
        weekNo: 0,
    };
    constructor() {
        super();
        this.handler = new ziuqthingHandler();
        this.handler.listen(() => {
            this.forceUpdate();
        });
        const lsValues = getFromLocalStorage();
        if (lsValues.date !== "x") {
            this.bootstrapped = lsValues;
        } else {
            clearLocalStorage();
        }
    }
    render() {
        if (
            this.bootstrapped.date !== "x" &&
            this.handler.mode === "ONCE_A_DAY"
        ) {
            return (
                <ScoreBoard
                    points={this.bootstrapped.score}
                    possiblePoints={this.bootstrapped.possibleScore}
                    answerCode={this.bootstrapped.answerCode}
                    submitted={this.bootstrapped.submitted}
                />
            );
        }
        if (this.handler.isDone()) {
            return (
                <ScoreBoard
                    points={this.handler.points}
                    possiblePoints={this.handler.possiblePoints()}
                    answerCode={this.handler.answerCode}
                />
            );
        }
        return (
            <Question
                question={this.handler.current}
                key={"question" + this.handler.currentID}
            />
        );
    }
}
const assetPreloader = {
    done: false,
    running: false,
    preloadListImg: [
        "ovn.jpg",
        "barthes.jpg",
        "mdv.png",
        "lietome.jpg",
        "chocobo.jpg",
    ],
    preloadListVid: ["MammaBursdag.mp4"],
    videoData: {},
    totalToLoad: 0,
    hasLoaded: 0,
    async preload(onDone) {
        if (assetPreloader.running || assetPreloader.done) {
            return;
        }
        assetPreloader.running = true;
        const once = (cb) => {
            let run = false;
            return () => {
                if (run === true) {
                    return;
                }
                run = true;
                return cb();
            };
        };
        assetPreloader.totalToLoad =
            assetPreloader.preloadListImg.length +
            assetPreloader.preloadListVid.length;
        const onHasLoadedEntity = () => {
            assetPreloader.hasLoaded++;
            if (assetPreloader.hasLoaded === assetPreloader.totalToLoad) {
                assetPreloader.done = true;
                onDone();
            }
        };
        for (const url of assetPreloader.preloadListImg) {
            const img = new Image();
            img.src = url;
            img.addEventListener("load", once(onHasLoadedEntity));
        }
        for (const url of assetPreloader.preloadListVid) {
            const vid = await fetch(url);
            const blob = await vid.blob();
            assetPreloader.videoData[url] = URL.createObjectURL(blob);
            onHasLoadedEntity();
        }
    },
};

class App extends React.Component<{||}> {
    imageClicks: number = 0;
    logoImageClick() {
        this.imageClicks++;
        if (this.imageClicks > 4) {
            clearLocalStorage();
            window.location.reload();
        }
    }
    render(): React.Node {
        if (!TBAudio.loaded || assetPreloader.done !== true) {
            TBAudio.init(() => this.forceUpdate());
            assetPreloader.preload(() => this.forceUpdate());
            return (
                <Container>
                    <Row>
                        <Col>
                            Laster data… <Spinner />
                        </Col>
                    </Row>
                    <Row>
                        <Col>Dette kan ta nokre minutt.</Col>
                    </Row>
                    <Row>
                        <Col>Slå på lyden!</Col>
                    </Row>
                </Container>
            );
        }
        if (/(MSIE|Trident)/.test(navigator.userAgent)) {
            return (
                <Container>
                    <Row>
                        <Col>
                            <Alert color="danger">
                                <h3>Internet Explorer</h3>
                                Oops, det ser ut som at du har opna quizen i
                                Internet Explorer. Dette er ein nettlesar som er
                                utdatert, og som ikkje vil klare å vise quizen
                                skikkeleg. Opne sida opp i Firefox, Edge eller
                                Chrome. Er du på jobb? Start Edge eller Chrome.
                            </Alert>
                        </Col>
                    </Row>
                </Container>
            );
        }
        return (
            <Container>
                <Row>
                    <Col>
                        <header className="ps-3">
                            <h4 className="mt-2">Torill 60</h4>
                        </header>
                    </Col>
                </Row>
                <Ziuqthing />
            </Container>
        );
    }
}

export default App;
